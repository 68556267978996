import * as React from "react"
import { ContactStyles } from "./ContactStyles"

const Contact = () => {
  return (
    <ContactStyles>

      <form
        name="contact"
        method="post"
        action="/"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact"/>
        <div>
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" />
        </div>
        <div>
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" rows="6" required />
        </div>
        <div>
          <input type="submit" value="Send Message." />
        </div>
      </form>
  
    </ContactStyles>

    
  )
}

export default Contact
