import styled from "styled-components"

export const ContactStyles = styled.section`
  padding: var(--sectionPadding) var(--borderSpacing);
  max-width: 750px;
  box-sizing: content-box;
  margin: 0 auto;

  input {
    height: 50px;
    margin-bottom: 1.25rem;

    @media (min-width: 1200px) {
      margin-bottom: 1.875rem;
    }
  }
  
  input,
  textarea {
    background-color: #222;
    color: #fff;
    border: none;
    border-bottom: 3px solid transparent;
    border-left: 1px solid white;
    width: 100%;
    font-size: 1rem;
    font-weight: 900;
    font-family: "Heebo", sans-serif;
    padding: 15px;
    transition: border 0.3s;
    outline: none;

    &:focus {
      border-bottom: 3px solid var(--primary);
      border-left: 3px solid transparent;
    }

    &::-webkit-input-placeholder {
      color: var(--inActive);
    }

    &::-moz-placeholder {
      color: var(--inActive);
    }

    &:-ms-input-placeholder {
      color: var(--inActive);
    }

    &:-moz-placeholder {
      color: var(--inActive);
    }
  }
  input[type="submit"] {
    max-width: max-content;
    border: 1px solid #fff;
    background: #000;
    padding-top: 0;
    padding-bottom: 0;
    &:hover {
      border: 1px solid var(--primary);
    }
  }
  textarea {
    margin-bottom: 2.125rem;
  }
`
